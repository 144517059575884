import getVersion from './lib/get-version';

export const logHello = () => {
    console.log(
        `%cWelcome to TopWrite!%c
`,
        `padding-top: 0.5em; font-size: 2em;`,
        'padding-bottom: 0.5em;',
    );

    console.log(`[ua:version] ${navigator.userAgent}`);
    console.log(`[client:version] v${getVersion()}`);
};
